<template>
  <Drawer v-model="isShow" width="1200" title="关联查询" draggable>
    <Tabs type="card" v-if="isShow">
      <TabPane label="收入明细">
        <PayLog :param="paylogparam"></PayLog>
      </TabPane>
      <TabPane label="退款明细">
        <RefundPayLog :param="refundpaylogparam"></RefundPayLog>
      </TabPane>
    </Tabs>
  </Drawer>
</template>

<script>
import PayLog from "./paylog.vue";
import RefundPayLog from "./refundpaylog.vue";
export default {
  name: "relevance-query",
  data() {
    return {
      isShow: false,
      paylogparam: {},
      refundpaylogparam: {},
    };
  },
  components: { PayLog, RefundPayLog },
};
</script>

<style lang="scss"></style>
