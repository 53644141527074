<template>
  <div class="main-container">
    <!--    搜索块-->
    <Form
      ref="PayLogSearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="编号:" prop="OrderCode">
              <Input
                placeholder="请输入订单/奖金编号"
                v-model="params.OrderCode"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="内部流水号:" prop="PayID">
              <Input
                placeholder="请输入内部流水号"
                v-model="params.PayID"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="外部流水号:" prop="PaySerial">
              <Input
                placeholder="请输入外部流水号"
                v-model="params.PaySerial"
                clearable
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button type="success" icon="md-cloud-download" @click="ExportPayLog"
            >导出</Button
          >
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
            >收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
            >搜索</Button
          >
          <Button class="ml2 search-form-container__button" @click="handleReset"
            >重置</Button
          >
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="payLogTable"
      :columns="payLogListOptions"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="SortChange"
      stripe
    >
      <template slot="OrderCode" slot-scope="{ row }">
        <Span
          style="color: green; cursor: pointer"
          @click="openOrderDrawer(row, true)"
          >{{ row.OrderCode }}</Span
        >
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <Modal v-model="EditView" width="360" class-name="i-modal">
      <p slot="header" style="color: green; text-align: center">
        <Icon type="information-circled"></Icon>
        <span>支付状态</span>
      </p>
      <div style="text-align: center">
        <Form ref="form" class="modelViewFromCheck">
          <FormItem prop="ID" label="支付状态">
            <Select clearable v-model="Status" placeholder="支付状态">
              <Option
                v-for="item in PayLogPayStatusEnum"
                :value="item.ID"
                :key="item.ID"
                >{{ item.CN }}</Option
              >
            </Select>
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <Button
          type="success"
          size="large"
          long
          @click="handleSuperBonus"
          :loading="BonusLoading"
          >确认</Button
        >
      </div>
    </Modal>
    <!--    订单明细信息展示抽屉-->
    <OrderDetailDrawer ref="orderDetailDrawer" />
  </div>
</template>

<script>
import api from "@/api";
import moment from "moment";
import bizEnum from "@/libs/bizEnum";
import OrderDetailDrawer from "@/views/order/components/detail-drawer.vue";

export default {
  name: "paylog",
  props: ["param"],
  data() {
    return {
      EditView: false,
      RowID: 0,
      Status: 0,
      tableLoading: false,
      tableHeight: 0,
      BonusLoading: false,
      params: {
        OrderCode: "",
        PayID: "",
        PaySerial: "",
        PayConfigID: "",
        DateBegin: "",
        DateEnd: "",
        Type: "",
        PayType: "",
        PayStatus: "2,3,4",
        Page: 1,
        PageSize: 10,
        SortName: "PayDate",
        SortOrder: "Desc",
      },
      payLogListOptions: [
        {
          title: "订单/奖金编号",
          key: "OrderCode",
          slot: "OrderCode",
          minWidth: 170,
        },
        { title: "商户名称", key: "PayConfigName", minWidth: 100 },
        { title: "支付方式", key: "PayTypeCN", minWidth: 100 },
        { title: "内部流水号", key: "PayID", minWidth: 180 },
        { title: "外部流水号", key: "PaySerial", minWidth: 210 },
        {
          title: "支付金额",
          sortable: "custom",
          key: "PayMoney",
          minWidth: 90,
        },
        {
          title: "退款金额",
          sortable: "custom",
          key: "RealRefund",
          minWidth: 90,
        },
        {
          title: "手续费",
          sortable: "custom",
          key: "HandleCost",
          minWidth: 90,
        },
        {
          title: "物流费",
          sortable: "custom",
          key: "LogisticsCost",
          minWidth: 90,
        },
        {
          title: "支付时间",
          sortable: "custom",
          key: "PayDate",
          minWidth: 135,
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
    };
  },
  computed: {
    PayLogTypeEnum() {
      return bizEnum.PayLogTypeEnum;
    },
    PayLogPayTypeEnum() {
      return bizEnum.PayLogPayTypeEnum;
    },
    PayLogPayStatusEnum() {
      return bizEnum.PayLogPayStatusEnum;
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.payLogListOptions.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        fixed: "right",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
      });
    }
    if (this.param) {
      this.params.PayConfigID = this.param.PayConfigID;
      this.params.DateBegin = this.param.DateBegin;
      this.params.DateEnd = this.param.DateEnd;
      this.params.PayType = this.param.PayType;
    } else {
      this.params.PayConfigID = "";
      this.params.DateBegin = "";
      this.params.DateEnd = "";
      this.params.PayType = "";
    }
    this.loadPayLogList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadPayLogList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadPayLogList();
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadPayLogList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    async ExportPayLog() {
      try {
        let filename = `${moment(this.params.DateBegin).format(
          "YYYYMMDD"
        )}-${moment(this.params.DateEnd).format("YYYYMMDD")}支付统计明细.xls`;
        await api.ExportPayLogList({
          exportName: filename,
          param: this.params,
          paytype: 0,
        });
        this.$Message.success("导出成功");
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        console.error(error);
      }
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadPayLogList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadPayLogList();
    },
    // 日期变化方法
    Dateupdate(value) {
      this.params.DateBegin = value[0];
      this.params.DateEnd = value[1];
    },
    editDrawer(row) {
      this.RowID = row.ID;
      this.Status = row.PayStatus;
      this.EditView = true;
    },
    async loadPayLogList() {
      this.tableLoading = true;
      try {
        const res = await api.GetPayLogList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadPayLogList();
    },
    handleReset() {
      this.$refs.PayLogSearchForm.resetFields();
      this.loadPayLogList();
    },
    // 远程排序
    SortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadPayLogList();
    },
    onUpdated() {
      this.$delayLoad(() => {
        this.loadPayLogList();
      });
    },
    async openOrderDrawer(row) {
      try {
        let res = await api.GetOrderByCode({
          code: row.OrderCode,
        });
        if (res.Data) {
          this.$refs.orderDetailDrawer.obj = res.Data;
          this.$refs.orderDetailDrawer.isShow = true;
          this.$refs.orderDetailDrawer.isEdit = false;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    async handleOpenDelete(row) {
      const _this = this;
      _this.$Modal.confirm({
        title: "提示",
        content: "您确定要删除这条记录吗？",
        loading: true,
        onOk: function () {
          _this.Delete(row);
        },
      });
    },
    async Delete(row) {
      try {
        const res = await api.DeleteBonusReflect({
          id: row.ID,
        });
        if (res.Data) {
          row.Status = -99;
          row.StatusCN = "已删除";
          const index = this.resObj.DataList.findIndex(
            (item) => item.ID === row.ID
          );
          this.resObj.DataList.splice(index, 1, row);
          this.$Message.success("删除成功");
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    async handleSuperBonus() {
      this.BonusLoading = true;
      try {
        await api.SavePayLogStatus({
          id: this.RowID,
          status: this.Status,
        });

        const temp = this.resObj.DataList.find(
          (item) => item.ID === this.RowID
        );
        temp.PayStatus = this.Status;
        if (temp.PayStatus === 0) {
          temp.PayStatusCN = "未支付";
        }
        if (temp.PayStatus === 1) {
          temp.PayStatusCN = "支付失败";
        }
        if (temp.PayStatus === 2) {
          temp.PayStatusCN = "支付成功";
        }
        if (temp.PayStatus === 3) {
          temp.PayStatusCN = "已退款";
        }
        if (temp.PayStatus === 4) {
          temp.PayStatusCN = "部分退款";
        }
        this.BonusLoading = false;
        this.$Message.success("操作成功");
        this.EditView = false;
      } catch (err) {
        this.BonusLoading = false;
        this.$Notice.error({
          title: "错误",
          desc: err,
        });
      }
    },
    // 修改成功，更改列表数据
    handleEditSuccess(res) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === res.ID
      );
      this.resObj.DataList.splice(index, 1, res);
    },
  },
  components: { OrderDetailDrawer },
};
</script>
