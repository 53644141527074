<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="PaySearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="5">
            <FormItem label="统计纬度:" prop="QueryType">
              <Select
                transfer
                @on-change="changeQueryType"
                v-model="params.QueryType"
                placeholder="统计纬度"
              >
                <Option :value="0">累计统计</Option>
                <Option :value="1">按月统计</Option>
                <Option :value="2">按日统计</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem label="商户名称:" prop="Name">
              <Input placeholder="请输入商户名称" v-model="params.Name" />
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem label="支付方式:" prop="PayType">
              <Select
                transfer
                multiple
                v-model="PayTypeArr"
                placeholder="支付方式"
                clearable
              >
                <Option :value="10">微信</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="7">
            <FormItem label="日期范围:" prop="TempDate">
              <Date-picker
                style="width: 100%"
                transfer
                clearable
                v-model="TempDate"
                ref="date"
                type="datetimerange"
                :options="options"
                placeholder="日期范围"
                format="yyyy-MM-dd HH:mm:ss"
                @on-change="Dateupdate"
              ></Date-picker>
            </FormItem>
            <!-- <FormItem label="年份:"
                    prop="year">
            <Select transfer
                    v-model="params.year"
                    placeholder="年份"
                    clearable>
              <Option :value="2018">2018</Option>
              <Option :value="2019">2019</Option>
              <Option :value="2020">2020</Option>
              <Option :value="2021">2021</Option>
            </Select>
          </FormItem> -->
          </Col>
          <!-- <Col span="6"
               class="mt5">
          <Button class="ml2"
                  size="small"
                  type="primary"
                  icon="ios-search"
                  @click="handleSearch">搜索</Button>
          <Button type="success"
                  class="ml2"
                  size="small"
                  @click="exportOrder"
                  icon="md-cloud-download">导出明细</Button>
          <Button class="ml2"
                  size="small"
                  @click="handleReset">重置</Button>
          </Col> -->
        </Row>
      </div>
      <Row type="flex" justify="space-between">
        <Col span="12" style="text-align: left">
          <Button type="success" icon="md-cloud-download" @click="exportOrder"
            >导出明细</Button
          >
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
            >收起 <Icon type="ios-arrow-up"
          /></span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
            >搜索</Button
          >
          <Button class="ml2 search-form-container__button" @click="handleReset"
            >重置</Button
          >
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      v-if="params.QueryType === 0"
      class="mt10 main-table"
      :row-class-name="RowClassName"
      :loading="tableLoading"
      :height="tableHeight"
      ref="orderExportTable"
      :columns="orderExportListOptions"
      :data="resObj.DataList"
      highlight-row
      size="small"
      stripe
    >
    </Table>
    <Table
      v-if="params.QueryType === 2"
      class="mt10 main-table"
      :row-class-name="RowClassName"
      :loading="tableLoading"
      :height="tableHeight"
      ref="orderExportTable"
      :columns="DateorderExportListOptions"
      :data="resObj.DataList"
      highlight-row
      size="small"
      stripe
    >
      <template slot="Action" slot-scope="{ row }">
        <Button
          class="ml2"
          size="small"
          type="primary"
          v-if="row.PayType !== 99"
          @click="openQuery(row)"
          >关联查询</Button
        >
      </template>
    </Table>
    <Table
      v-if="params.QueryType === 1"
      class="mt10 main-table"
      :row-class-name="RowClassName"
      :loading="tableLoading"
      :height="tableHeight"
      ref="orderExportTable"
      :columns="MonthorderExportListOptions"
      :data="resObj.DataList"
      highlight-row
      size="small"
      stripe
    >
    </Table>
    <!--    分页-->
    <!-- <Row class="mt10">
      <Col span="24"
           class="flex-end">
      <Page :total="resObj.TotalCount"
            :current="resObj.CurrentPage"
            @on-page-size-change="handleChangePageSize"
            @on-change="handleChangePage"
            size="small"
            show-elevator
            show-sizer
            show-total />
      </Col>
    </Row> -->
    <relevance-query ref="relevanceQuery" />
  </Layout>
</template>

<script>
import RelevanceQuery from "./components/relevance-query.vue";
import api from "@/api";
import moment from "moment";
import Layout from "@/components/layout/index.vue";

export default {
  name: "yearpayamount",
  data() {
    return {
      TempDate: [
        `${moment()
          .subtract(0, "day")
          .startOf("day")
          .format("YYYY-MM-DD")} 00:00:00`,
        `${moment()
          .subtract(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DD")} 00:00:00`,
      ],
      tableLoading: false,
      tableHeight: 0,
      RowID: 0,
      PayTypeArr: [],
      BonusLoading: false,
      params: {
        PayType: "",
        QueryType: 2,
        Name: "",
        Page: 1,
        PageSize: 10,
        begindate: "",
        enddate: "",
      },
      orderExportListOptions: [
        { title: "商户名称", key: "PayConfigName", minWidth: 100 },
        { title: "支付方式", key: "PayTypeCN", minWidth: 100 },
        { title: "商户号", key: "PayID", minWidth: 100 },
        { title: "总收入", key: "TotalAmount", minWidth: 80 },
        { title: "净收入", key: "PayMoney", minWidth: 80 },
        { title: "总退款", key: "RealRefund", minWidth: 80 },
        { title: "手续费", key: "HandleCost", minWidth: 80 },
        { title: "物流费", key: "FreightCost", minWidth: 80 },
      ],
      DateorderExportListOptions: [
        { title: "日期", key: "ReportDate", minWidth: 100 },
        { title: "商户名称", key: "PayConfigName", minWidth: 100 },
        { title: "支付方式", key: "PayTypeCN", minWidth: 100 },
        { title: "商户号", key: "PayID", minWidth: 100 },
        { title: "总收入", key: "TotalAmount", minWidth: 80 },
        { title: "净收入", key: "PayMoney", minWidth: 80 },
        { title: "总退款", key: "RealRefund", minWidth: 80 },
        { title: "手续费", key: "HandleCost", minWidth: 80 },
        { title: "物流费", key: "FreightCost", minWidth: 80 },
        {
          title: "操作",
          key: "action",
          slot: "Action",
          align: "center",
          fixed: "right",
          minWidth: 80,
          maxWidth: 80,
        },
      ],
      MonthorderExportListOptions: [
        { title: "日期", key: "ReportDate", minWidth: 100 },
        { title: "商户名称", key: "PayConfigName", minWidth: 100 },
        { title: "支付方式", key: "PayTypeCN", minWidth: 100 },
        { title: "商户号", key: "PayID", minWidth: 100 },
        { title: "总收入", key: "TotalAmount", minWidth: 80 },
        { title: "净收入", key: "PayMoney", minWidth: 80 },
        { title: "总退款", key: "RealRefund", minWidth: 80 },
        { title: "手续费", key: "HandleCost", minWidth: 80 },
        { title: "物流费", key: "FreightCost", minWidth: 80 },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      // 日期快捷选择
      options: {
        shortcuts: [
          {
            text: "近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "近一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "近三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    const _this = this;
    _this.params.begindate = _this.TempDate[0];
    _this.params.enddate = _this.TempDate[1];
    // this.loadOrderExportList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
      console.log(self.tableHeight);
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadOrderExportList();
        return false;
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        const _this = this;
        _this.params.begindate = _this.TempDate[0];
        _this.params.enddate = _this.TempDate[1];
        this.handleSearch();
      }
    });
  },
  activated() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadOrderExportList();
        return false;
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  methods: {
    openQuery(row) {
      this.$refs.relevanceQuery.paylogparam = {
        PayConfigID: row.PayConfigID,
        DateBegin: row.PayDate,
        DateEnd: row.RefundDate,
        PayType: row.PayType,
      };
      this.$refs.relevanceQuery.refundpaylogparam = {
        PayConfigID: row.PayConfigID,
        DateBegin: row.PayDate,
        DateEnd: row.RefundDate,
        PayType: row.PayType,
      };
      this.$refs.relevanceQuery.isShow = true;
    },
    changeQueryType() {
      this.resObj = {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      };
    },
    // 选中行样式
    RowClassName(row) {
      if (row.PayTypeCN === "小计") {
        return "sum";
      } else if (row.PayTypeCN === "总计") {
        return "total";
      }
      return "";
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadOrderExportList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadOrderExportList();
    },
    // 日期变化方法
    Dateupdate(value) {
      this.params.begindate = value[0];
      this.params.enddate = value[1];
    },
    async loadOrderExportList() {
      this.tableLoading = true;
      if (this.PayTypeArr.length > 0) {
        this.params.PayType = this.PayTypeArr.join(",");
      } else {
        this.params.PayType = "";
      }
      try {
        if (this.params.QueryType === 0) {
          const res = await api.GetYearPayAmount(this.params);
          this.resObj.DataList = res.Data;
        } else {
          const res = await api.GetDateYearPayAmount(this.params);
          this.resObj.DataList = res.Data;
        }
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    async exportOrder() {
      try {
        // let filename = `${moment(this.params.begindate).format(
        //   "YYYYMMDD"
        // )}-${moment(this.params.enddate).format("YYYYMMDD")}支付统计.xls`;
        if (this.params.QueryType === 0) {
          await api.ExportYearPayAmount(this.params);
        } else {
          await api.ExportDateYearPayAmount(this.params);
        }
        this.$Message.success("导出成功");
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        console.error(error);
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadOrderExportList();
    },
    handleReset() {
      this.TempDate = [
        `${moment()
          .subtract(0, "day")
          .startOf("day")
          .format("YYYY-MM-DD")} 00:00:00`,
        `${moment()
          .subtract(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DD")} 00:00:00`,
      ];
      this.params.begindate = this.TempDate[0];
      this.params.enddate = this.TempDate[1];
      this.params.PayType = "";
      this.PayTypeArr = [];
      this.$refs.PaySearchForm.resetFields();
      this.loadOrderExportList();
    },
  },
  components: { RelevanceQuery, Layout },
};
</script>
<style lang="less">
.ivu-table .sum td {
  background-color: #8ea9db !important;
  // color: rgb(0, 0, 0);
}
.ivu-table .total td {
  background-color: #f4b084 !important;
  // color: rgb(0, 0, 0);
}
</style>
